/* src/styles/UpgradePage.css */

.upgrade-page {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
}

.upgrade-container {
    background: white;
    padding: 2rem;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 500px;
}

.upgrade-container h1 {
    color: #2d3748;
    text-align: center;
    margin-bottom: 0.5rem;
    font-size: 24px;
}

.subtitle {
    color: #718096;
    text-align: center;
    margin-bottom: 2rem;
    font-size: 16px;
}

.upgrade-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.form-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.form-group label {
    color: #4a5568;
    font-size: 14px;
    font-weight: 500;
}

.form-group input {
    padding: 0.75rem;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    font-size: 16px;
    transition: border-color 0.2s;
}

.form-group input:focus {
    outline: none;
    border-color: #4299e1;
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.1);
}

.upgrade-submit-button {
    background: #4299e1;
    color: white;
    padding: 0.75rem;
    border: none;
    border-radius: 6px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
}

.upgrade-submit-button:hover {
    background: #3182ce;
}

.upgrade-submit-button:disabled {
    background: #a0aec0;
    cursor: not-allowed;
}

.error-message {
    color: #e53e3e;
    text-align: center;
    font-size: 14px;
    margin-top: -0.5rem;
}

/* Media Queries */
@media (max-width: 640px) {
    .upgrade-container {
        padding: 1.5rem;
    }

    .upgrade-container h1 {
        font-size: 20px;
    }

    .subtitle {
        font-size: 14px;
    }

    .form-group input {
        font-size: 14px;
    }
}