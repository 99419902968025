/* src/signup.css */

.signup-container {
  max-width: 400px;
  margin: 50px auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: white;
}

.signup-container h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
  font-size: 24px;
}

.signup-container input {
  width: 100%;
  padding: 12px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.3s ease;
}

.signup-container input:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.1);
}

.signup-container button {
  width: 100%;
  padding: 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  transition: background-color 0.3s ease;
}

.signup-container button:hover {
  background-color: #0056b3;
}

.signup-container button:active {
  transform: scale(0.98);
}

.error {
  color: #dc3545;
  margin-bottom: 15px;
  text-align: center;
  font-size: 14px;
  padding: 10px;
  background-color: #fde8e8;
  border-radius: 4px;
}

.switch-form {
  text-align: center;
  margin-top: 15px;
  font-size: 14px;
  color: #666;
}

.switch-form button {
  background: none;
  border: none;
  color: #007bff;
  padding: 0;
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
  width: auto;
}

.switch-form button:hover {
  color: #0056b3;
  background: none;
}

/* Password strength indicator */
.password-strength {
  margin-top: -10px;
  margin-bottom: 15px;
  font-size: 12px;
  color: #666;
}

/* Form group spacing */
.form-group {
  margin-bottom: 15px;
}

/* Label styling */
.form-group label {
  display: block;
  margin-bottom: 5px;
  color: #444;
  font-size: 14px;
}