/* Assistant.css */

/* Container and Layout */
.chat-container {
    width: 100%;
    min-height: 100vh;
    background-color: #000000;
    color: white;
    padding: 1rem;
    transition: all 0.3s ease;
}

.chat-container.light {
    background-color: #ffffff;
    color: #000000;
}

.chat-inner {
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: #111111;
    border-radius: 1rem;
    overflow: hidden;
}

.light .chat-inner {
    background-color: #ffffff;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

/* Header Styles */
.chat-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: #1a1a1a;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.light .chat-header {
    background-color: #f8f9fa;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.header-left {
    display: flex;
    align-items: center;
    gap: 12px;
}

.header-right {
    display: flex;
    align-items: center;
    gap: 8px;
}

.header-icon {
    color: #22c55e;
}

.header-title {
    font-size: 1.25rem;
    font-weight: bold;
}

.light .header-title {
    color: #000000;
}

/* Message Counter */
.message-counter {
    display: flex;
    align-items: center;
    background: rgba(0, 0, 0, 0.1);
    padding: 6px 12px;
    border-radius: 16px;
    font-size: 14px;
    margin-right: 12px;
}

.dark .message-counter {
    background: rgba(255, 255, 255, 0.1);
}

.limit-reached {
    color: #ff5252;
}

/* Upgrade Button Styles */
.upgrade-pro-button {
    background: linear-gradient(135deg, #ff6b6b 0%, #ff5252 100%);
    color: white;
    padding: 6px 12px;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;
    transition: all 0.2s ease;
    margin-right: 12px;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.upgrade-pro-button:hover {
    transform: translateY(-1px);
    box-shadow: 0 4px 8px rgba(255, 82, 82, 0.2);
}

/* Theme Toggle and Logout Buttons */
.theme-toggle, .logout-button {
    background: none;
    border: none;
    padding: 0.5rem;
    border-radius: 0.375rem;
    cursor: pointer;
    color: var(--text-color);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s ease;
}

.theme-toggle:hover, .logout-button:hover {
    background-color: var(--hover-color);
}

.dark .theme-toggle, .dark .logout-button {
    color: #fff;
}

.dark .theme-toggle:hover, .dark .logout-button:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.light .theme-toggle, .light .logout-button {
    color: #000;
}

.light .theme-toggle:hover, .light .logout-button:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

/* Chat Window */
.chat-window {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.messages-container {
    flex: 1;
    overflow-y: auto;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

/* Empty State */
.empty-state {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: #666;
    padding: 2rem;
    text-align: center;
}

.empty-state svg {
    opacity: 0.5;
    margin-bottom: 1rem;
}

.empty-state p {
    margin: 0.5rem 0;
    font-size: 1.1rem;
}

.empty-state small {
    color: #888;
}

/* Messages */
.message-wrapper {
    display: flex;
    margin-bottom: 0.5rem;
}

.message-wrapper.user {
    justify-content: flex-end;
}

.message {
    max-width: 70%;
    padding: 0.75rem;
    border-radius: 0.5rem;
    background-color: #1a1a1a;
}

.light .message {
    background-color: #f0f0f0;
    color: #000000;
}

.message.user {
    background-color: #22c55e;
    color: white;
}

.message-timestamp {
    font-size: 12px;
    color: #666;
    margin-top: 4px;
    text-align: right;
}

.dark .message-timestamp {
    color: #999;
}

/* Limit Banner */
.limit-banner {
    background: linear-gradient(135deg, #ff6b6b 0%, #ff5252 100%);
    color: white;
    padding: 15px;
    margin: 10px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dark .limit-banner {
    background: linear-gradient(135deg, #2c3e50 0%, #34495e 100%);
}

.limit-banner .upgrade-button {
    background: white;
    color: #ff5252;
    border: none;
    padding: 8px 16px;
    border-radius: 6px;
    font-weight: 500;
    text-decoration: none;
    transition: transform 0.2s;
}

.dark .limit-banner .upgrade-button {
    background: #ecf0f1;
    color: #2c3e50;
}

.limit-banner .upgrade-button:hover {
    transform: scale(1.05);
}

/* Input Container */
.input-container {
    padding: 1rem;
    background-color: #111111;
}

.light .input-container {
    background-color: #ffffff;
}

.input-form {
    display: flex;
    gap: 0.5rem;
    background-color: #1a1a1a;
    border-radius: 0.5rem;
    padding: 0.75rem;
}

.light .input-form {
    background-color: #f0f0f0;
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.input-wrapper {
    position: relative;
    flex: 1;
    display: flex;
    align-items: center;
}

.chat-input {
    width: 100%;
    padding: 0.75rem 2.5rem 0.75rem 1rem;
    background-color: transparent;
    color: #e0e0e0;
    border: none;
    font-size: 0.95rem;
}

.chat-input:disabled {
    background-color: #2d2d2d;
    cursor: not-allowed;
}

.light .chat-input:disabled {
    background-color: #f5f5f5;
}

.light .chat-input {
    color: #000000;
}

.chat-input:focus {
    outline: none;
}

.chat-input::placeholder {
    color: #6b7280;
}

/* File Upload */
.file-label {
    position: absolute;
    right: 0.75rem;
    cursor: pointer;
    color: #6b7280;
    display: flex;
    align-items: center;
    padding: 0.5rem;
}

.file-input {
    display: none;
}

/* Send Button */
.send-button {
    background: transparent;
    border: none;
    color: #6b7280;
    cursor: pointer;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color 0.2s ease;
}

.send-button:hover {
    color: #22c55e;
}

.send-button:disabled {
    color: #4b5563;
    cursor: not-allowed;
}

/* Loading Animation */
.loading-dots {
    display: flex;
    gap: 0.5rem;
    padding: 0.5rem;
    justify-content: center;
}

.dot {
    width: 0.5rem;
    height: 0.5rem;
    background-color: #6b7280;
    border-radius: 50%;
    animation: bounce 1s infinite;
}

.dot:nth-child(2) {
    animation-delay: 0.1s;
}

.dot:nth-child(3) {
    animation-delay: 0.2s;
}

@keyframes bounce {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-0.25rem);
    }
}

/* Status Messages */
.status-message {
    padding: 1rem;
    border-radius: 0.5rem;
    margin: 0.5rem;
    text-align: center;
}

.status-message.error {
    background-color: rgba(220, 38, 38, 0.5);
}

.status-message.success {
    background-color: rgba(34, 197, 94, 0.5);
}

/* Floating Upgrade Reminder */
.floating-upgrade-reminder {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background: white;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    z-index: 1000;
}

.dark .floating-upgrade-reminder {
    background: #2c3e50;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
}

.floating-upgrade-reminder p {
    margin: 0;
    color: #666;
}

.dark .floating-upgrade-reminder p {
    color: #ecf0f1;
}

.floating-upgrade-button {
    background: #ff5252;
    color: white;
    padding: 8px 16px;
    border-radius: 6px;
    text-decoration: none;
    font-weight: 500;
    transition: all 0.2s ease;
}

.floating-upgrade-button:hover {
    background: #ff6b6b;
    transform: translateY(-1px);
}

/* Scrollbar Styles */
.chat-container::-webkit-scrollbar {
    width: 6px;
}

.chat-container::-webkit-scrollbar-track {
    background: transparent;
}

.chat-container::-webkit-scrollbar-thumb {
    background-color: rgba(156, 163, 175, 0.3);
    border-radius: 3px;
}

.chat-container::-webkit-scrollbar-thumb:hover {
    background-color: rgba(156, 163, 175, 0.5);
}

.light::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
}

.light::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.3);
}

.unlimited-badge {
    background: #22c55e;
    color: white;
    padding: 4px 8px;
    border-radius: 12px;
    font-weight: 500;
}

.dark .unlimited-badge {
    background: #4ade80;
}

/* Media Queries */
@media (max-width: 768px) {
    .chat-container {
        padding: 0;
    }

    .chat-inner {
        max-width: 100%;
        height: 100vh;
        border-radius: 0;
    }

    .message {
        max-width: 85%;
    }

    .input-container {
        padding: 0.75rem;
    }

    .header-title {
        font-size: 1.125rem;
    }
}

@media (max-width: 480px) {
    .message {
        max-width: 90%;
        font-size: 0.95rem;
    }

    .chat-header {
        padding: 0.75rem;
    }

    .status-message {
        font-size: 0.875rem;
        padding: 0.75rem;
        margin: 0.5rem 0.25rem;
    }
}

@media (max-height: 667px) {
    .chat-header {
        padding: 0.5rem 1rem;
    }

    .messages-container {
        padding: 0.75rem;
    }

    .input-container {
        padding: 0.5rem;
    }
}

/* Accessibility */
@media (prefers-reduced-motion: reduce) {
    .dot {
        animation: none;
    }
    
    .message-wrapper,
    .chat-input,
    .send-button,
    .upgrade-pro-button,
    .floating-upgrade-button {
        transition: none;
    }
}